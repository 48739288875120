* {
  font-family: "JetBrains Mono", monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.cdnfonts.com/css/death-star');

.btnfos-0-3:disabled{
  cursor: no-drop;
}

.notification.show {
  opacity: 1;
}

.logo:hover {
  cursor: pointer;
}

.btnfos-0-3:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.logoPic:hover {
  cursor: pointer;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.btnfos-0-2:hover {
  border-color: black;
}

.left {
  text-align: left;
}

.wallet2Mobile {
  display: none;
}

@media screen and (max-width: 499px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 12px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 12px;
  }

  .copy-button {
    margin-left: 10px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 16px;
  }


  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 16px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0 0 20px #74747465;
    border: 5px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 16px;
    color: #f89592;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f89592;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 60px;
    color: white;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: black;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 60px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 20px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 40%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
   }

  #divImg1{
    margin-left: -40px;
  }

  #divImg2{
    margin-right: -40px;
  }

  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .img1 {
    width: 160px;
    height: 160px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }

  .img2 {
    width: 160px;
    height: 160px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;

    margin-left: auto;
    justify-content: center;

    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }


  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0 0 20px #74747465;
    border: 5px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 16px;
    color: #f89592;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f89592;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 50px;
    color: white;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: black;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 60px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 25px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 40%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: -10px;
  }

  #divImg1{
    margin-left: -40px;
  }

  #divImg2{
    margin-right: -40px;
  }

  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .img1 {
    width: 160px;
    height: 160px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }

  .img2 {
    width: 160px;
    height: 160px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 55px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;

    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 80%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0 0 20px #74747465;
    border: 5px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 16px;
    color: #f89592;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f89592;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 70px;
    color: white;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: black;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: fit-content;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 30px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;

  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -190px;
  }

  #divImg1{
    margin-left: -40px;
  }

  #divImg2{
    margin-right: -40px;
  }

  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
  }

  .img1 {
    width: 180px;
    height: 180px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }

  .img2 {
    width: 180px;
    height: 180px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }


  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 65px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;

    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0 0 20px #74747465;
    border: 5px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 16px;
    color: #f89592;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f89592;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 70px;
    color: white;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: black;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 30px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -250px;
  }

  #divImg1{
    margin-left: -40px;
  }

  #divImg2{
    margin-right: -40px;
  }


  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .img1 {
    width: 220px;
    height: 220px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }

  .img2 {
    width: 220px;
    height: 220px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }


  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 85px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;

    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 16px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    box-shadow: 0 0 20px #74747465;
    border: 5px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 16px;
    color: #f89592;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f89592;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "JetBrains Mono", monospace;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "JetBrains Mono", monospace;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 70px;
    color: white;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: black;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 100px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 30px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -250px;
  }

  #divImg1{
    margin-left: -90px;
  }

  #divImg2{
    margin-right: -90px;
  }


  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .img1 {
    width: 300px;
    height: 300px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }

  .img2 {
    width: 300px;
    height: 300px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }
}

@media screen and (min-width: 1920px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    width: fit-content;
    justify-content: center;
    padding: 25px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    border-radius: 20px;
  }

  code {
    font-size: 26px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 26px;
  }

  .copy-button {
    margin-left: 30px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(231, 255, 199);
    border-radius: 15px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 20px;
    padding: 10px;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }


  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 115px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: flex-end;
      display: flex;
     }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }


  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 24px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }


  .mintDiv {
    background-color: #0000004f;
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 10px solid black;
    padding: 30px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 30px;
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 22px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 100px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    height: 75px;
    width: 75px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 4px solid #ffffff;
    border-radius: 12px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 50px;
    height: 50px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 24px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 3px solid;
    margin-top: 5%;
    border-radius: 9px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 32px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 60%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 3px solid;
    margin-top: 5%;
    border-radius: 9px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 24px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 150px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 3px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
    margin-bottom: 10vh;

  }
  
  .introduction-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;

    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 150px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 3px solid rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 40px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -380px;
  }

  #divImg1{
    margin-left: -140px;
  }

  #divImg2{
    margin-right: -140px;
  }

  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .img1 {
    width: 370px;
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }

  .img2 {
    width: 370px;
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    width: fit-content;
    justify-content: center;
    padding: 50px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    border-radius: 45px;
  }

  code {
    font-size: 48px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 48px;
  }

  .copy-button {
    margin-left: 50px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 2px solid rgb(231, 255, 199);
    border-radius: 15px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 25px;
    padding: 30px;
    font-size: 42px;
  }


  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 155px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: flex-end;
      display: flex;
     }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon {
    padding-right: 28px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 55px;
    height: 55px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 34px;
    color: #ffffff;
  }


  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 33px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }


  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 50px;
    box-shadow: 0 0 20px #99999965;
    border: 10px solid black;
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .errorMessage {
    font-size: 32px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 32px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 135px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 35px;
    margin-left: 40px;
    margin-right: 40px;
    height: 100px;
    width: 100px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 4px solid #ffffff;
    border-radius: 15px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 70px;
    height: 70px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 34px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 4px solid;
    margin-top: 5%;
    border-radius: 12px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 44px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 4px solid;
    margin-top: 5%;
    border-radius: 12px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 35px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 200px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 5px solid rgb(255, 255, 255);
    padding: 30px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 65px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 58px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -500px;
  }

  #divImg1{
    margin-left: -140px;
  }

  #divImg2{
    margin-right: -140px;
  }

  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
  }

  .img1 {
    width: 510px;
    height: 510px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }

  .img2 {
    width: 510px;
    height: 510px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }
}

@media screen and (min-width: 3840px) {

  .container {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .container2 {
    display: flex;
    background-color: #ffffff;
    width: fit-content;
    justify-content: center;
    padding: 50px;
    margin-right: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    border-radius: 45px;
  }

  code {
    font-size: 48px;
    color: rgb(105, 45, 184);
    font-weight: bold;
  }

  .address-section p {
    color: black;
    margin: 0;
    font-size: 48px;
  }

  .copy-button {
    margin-left: 50px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 2px solid rgb(231, 255, 199);
    border-radius: 15px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 25px;
    padding: 30px;
    font-size: 42px;
  }


  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(255, 255, 255);
    font-size: 215px;
    font-family: 'Death Star', sans-serif;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 204, 255, 0.267), 0 -10px 20px #0099ff5e, 0 -18px 40px rgba(0, 195, 255, 0.212);
  }

  /*.nftPicDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: flex-end;
      display: flex;
     }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon {
    padding-right: 28px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 82px;
    height: 82px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 48px;
    color: #ffffff;
  }


  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    contain: content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: white;
    font-size: 45px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000000;
    position: relative;
  }

  .pricesM div {
    color: white;
    margin-bottom: 5px;
  }


  .mintDiv {
    background-color: #0000004f;
    width: 50%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 80px;
    border: 15px solid;
    /* Border thickness */
    border-image: linear-gradient(to right, #80169be1, #017bc2d8) 1;
  }

  .errorMessage {
    font-size: 42px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 45px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 180px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 55px;
    margin-left: 50px;
    margin-right: 50px;
    height: 135px;
    width: 135px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 6px solid #ffffff;
    border-radius: 20px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 95px;
    height: 95px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 48px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 22px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: rgb(42, 132, 235) 6px solid;
    margin-top: 5%;
    border-radius: 18px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 60px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 22px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 6px solid;
    margin-top: 5%;
    border-radius: 18px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 45px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1be5;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: rgba(20, 20, 20, 0.692);
    border-radius: 350px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 8px solid rgb(255, 255, 255);
    padding: 60px;
    box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(92, 224, 241, 0.945);
    }

    100% {
      box-shadow: 0 0 30px rgba(92, 127, 241, 0.945);
    }
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    color: white;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: 3px solid rgb(0, 217, 255);
    margin-left: auto;
    margin-right: auto;
    background-color: #03a3ff;
    box-shadow: 0 0 20px rgba(111, 234, 250, 0.555);
    color: rgb(255, 255, 255);
    letter-spacing: 5px;
    font-weight: bold;
    font-family: "Russo One", sans-serif;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }


  .totalSupply {
    text-align: center;
    font-size: 95px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 78px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: #1e1e1e;
    background: url('./assets/8548091.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #a0f351, #4a9108);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }

  .rocket {
    width: 15%;
    animation: float 6s ease-in-out infinite;
    cursor: pointer;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .walletConnect {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imgsMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    margin-top: -780px;
  }

  #divImg1{
    margin-left: -160px;
  }

  #divImg2{
    margin-right: -160px;
  }


  .div1 {
    rotate: -5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 60px;
  }

  .div2 {
    rotate: 5deg;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 60px;
  }

  .img1 {
    width: 790px;
    height: 790px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }

  .img2 {
    width: 790px;
    height: 790px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
  }
}